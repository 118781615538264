// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-categoria-categoria-js": () => import("./../../../src/components/categoria/Categoria.js" /* webpackChunkName: "component---src-components-categoria-categoria-js" */),
  "component---src-components-opinion-opinion-item-js": () => import("./../../../src/components/opinion/OpinionItem.js" /* webpackChunkName: "component---src-components-opinion-opinion-item-js" */),
  "component---src-components-opinion-opinion-list-js": () => import("./../../../src/components/opinion/OpinionList.js" /* webpackChunkName: "component---src-components-opinion-opinion-list-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actividades-js": () => import("./../../../src/pages/actividades.js" /* webpackChunkName: "component---src-pages-actividades-js" */),
  "component---src-pages-articulos-academicos-js": () => import("./../../../src/pages/articulos-academicos.js" /* webpackChunkName: "component---src-pages-articulos-academicos-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-ensenanza-js": () => import("./../../../src/pages/ensenanza.js" /* webpackChunkName: "component---src-pages-ensenanza-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-libros-js": () => import("./../../../src/pages/libros.js" /* webpackChunkName: "component---src-pages-libros-js" */)
}

